<template>
    <div class="flex h-screen">
      <div class="flex-1">
          <div class="iframe-container">
            <div class="md-layout">
              <div class="md-layout-item md-size-40">
                <md-field>
                  <label> Fecha Inicio</label>
                  <md-input v-model="fechainicio" type="date"></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-40">
                <md-field>
                  <label>Fecha Fin</label>
                  <md-input v-model="fechafin" type="date"></md-input>
                </md-field>
              </div>
              <div class="md-layout-item md-size-20">
                <md-button class="md-primary" @click.native="Filtramos()">Filtrar</md-button>
              </div>
            </div>
            <iframe v-if="iframeUrl" :src="iframeUrl" frameborder="0" width="100%" height="100vh"></iframe>
          </div>
        </div>
      </div>
  </template>
  
  <script>
  import store from "@/Store";
  import servMetabase from "@/Servicios/Dashboard/dashboard";
  
  export default {
    name: "Metabase-Representacion",
    data() {
      return {
        iframeUrl: "",    
        fechainicio: '',
        fechafin: ''
      };
    },
    computed: {
      usuario() {
        return store.state.usuario;
      }
    },
    mounted() {
      this.SetFechas();
      this.getToken();
    },
    methods: {
      async getToken() {
        try {
          const response = await servMetabase.Metabase(
            this.usuario.usuariosId,
            this.usuario.representacionId,
            this.fechainicio,
            this.fechafin
          );
          this.iframeUrl = response.data.iframeUrl;
        } catch (error) {
          console.error("Error al obtener el token:", error);
        }
      },
      SetFechas() {
        var date = new Date(),
          y = date.getFullYear(),
          m = date.getMonth();
        var firstDay = new Date(y, 0, 1);
        var lastDay = new Date(y, 11 + 1, 0);
        this.fechainicio = firstDay.toISOString().split('T')[0];;
        this.fechafin = lastDay.toISOString().split('T')[0];;
      },
      Filtramos() {
        this.getToken();
      }
    }
  };
  </script>
  
  <style scoped>
  .iframe-container {
    width: 100%;
    height: 100vh;
  }
  </style>