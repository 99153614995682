import { render, staticRenderFns } from "./FlujoSolicitud.vue?vue&type=template&id=796de738&scoped=true"
import script from "./FlujoSolicitud.vue?vue&type=script&lang=js"
export * from "./FlujoSolicitud.vue?vue&type=script&lang=js"
import style0 from "./FlujoSolicitud.vue?vue&type=style&index=0&id=796de738&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "796de738",
  null
  
)

export default component.exports