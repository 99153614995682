<template>
  <div
    class="wrapper"
    :class="[
      { 'nav-open': $sidebar.showSidebar },
      { rtl: $route.meta.rtlActive },
    ]"
  >
    <notifications></notifications>
    <side-bar
      :active-color="sidebarBackground"
      :background-image="sidebarBackgroundImage"
      :data-background-color="sidebarBackgroundColor"
    >
      <user-menu
        :title="usuario.usuarioNomCompleto"
        :rtlTitle="usuario.usuarioNombre"
        :avatar="usuario.imagen"
      ></user-menu>
      <mobile-menu></mobile-menu>
      <template slot="links">
        <sidebar-item
          v-for="link in menu"
          :key="link.id"
          :link="{
            name: link.nombre,
            icon: 'dashboard',
            path: '/' + link.controlador,
          }"
        >
          <sidebar-item
            v-for="subLink in link.inverseMenuPpadre"
            :key="subLink.id"
            :link="{
              name: subLink.nombre,
              icon: 'dashboard',
              path: '/' + subLink.controlador,
            }"
          >
          </sidebar-item>
        </sidebar-item>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <!-- <phone-plugin/>       -->

      <div
        :class="{ content: !$route.meta.hideContent }"
        @click="toggleSidebar"
      >
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </zoom-center-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
     <!-- Chat Widget -->
     <AIChatWidget 
      v-if="usuario && usuario.usuarioNomCompleto && (usuario.areaId == 2 || usuario.areaId == 3)"
      :user-name="usuario.usuarioNomCompleto"
      :user-avatar="usuario.imagen"
    />
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import FixedPlugin from "./FixedPlugin.vue";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
    document.getElementsByClassName(className)[0].scrollTop = 0;
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

function reinitScrollbar() {
  let docClasses = document.body.classList;
  let isWindows = navigator.platform.startsWith("Win");
  if (isWindows) {
    // if we are on windows OS we activate the perfectScrollbar function
    initScrollbar("sidebar");
    initScrollbar("sidebar-wrapper");
    initScrollbar("main-panel");

    docClasses.add("perfect-scrollbar-on");
  } else {
    docClasses.add("perfect-scrollbar-off");
  }
}

import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
// import PhonePlugin from "./PhonePlugin.vue";
import UserMenu from "./Extra/UserMenu.vue";
import { ZoomCenterTransition } from "vue2-transitions";
import servmenu from "@/Servicios/Menu/menu";
import store from "@/Store/index";
import ChatPlugin from "./ChatPlugin.vue";
import AIChatWidget from "@/paginas/Chat/index.vue";
export default {
  computed: {
    usuario() {
      return store.state.usuario;
    },
  },
  components: {
    TopNavbar,
    ContentFooter,
    // PhonePlugin, //es el engrane que nos permite personalizar diseño
    ChatPlugin,
    MobileMenu,
    UserMenu,
    ZoomCenterTransition,
    AIChatWidget
  },
  data() {
    return {
      sidebarBackgroundColor: "white",
      sidebarBackground: "danger",
      sidebarBackgroundImage: "./img/sidebar-2.jpg",
      sidebarMini: false,
      sidebarImg: true,
      menu: [],
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(true);
      }
    },
    minimizeSidebar() {
      if (this.$sidebar) {
        this.$sidebar.toggleMinimize();
      }
    },
    async recuperamosMenu() {
      const userobj = localStorage.getItem("pjUser");
      const user = JSON.parse(userobj);
      servmenu
        .listado(this.usuario.areaId)
        .then((m) => {
          this.menu = m.data;
        })
        .catch((e) => {});
      if (this.menu.length === 0 && user != null) {
        store.state.usuario = user;
        servmenu
          .listado(user.areaId)
          .then((m) => {
            this.menu = m.data;
          })
          .catch((e) => {});
      } else {
        this.$router.push("Login");
      }
    },
  },
  updated() {
    reinitScrollbar();
  },
  mounted() {
    reinitScrollbar();
  },
  created() {
    //console.log(this.usuario);
    if (this.usuario != null || this.usuario != undefined) {
      this.recuperamosMenu();
    }
  },
  watch: {
    sidebarMini() {
      this.minimizeSidebar();
    },
  },
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}
.main-panel .zoomIn {
  animation-name: zoomIn95;
}
@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.main-panel .zoomOut {
  animation-name: zoomOut95;
}

// Asegurarnos que el chat esté siempre visible y por encima del contenido
.chat-widget-container {
  z-index: 9999 !important; // Para que esté por encima del sidebar y otros elementos
}

// Ajustar posición cuando el sidebar está abierto
.nav-open .chat-widget-container {
  @media (min-width: 991px) {
    right: calc(24px + 260px); // 260px es el ancho típico del sidebar
    transition: right 0.3s ease;
  }
}

// Ajustar el chat para dispositivos móviles
@media (max-width: 990px) {
  .chat-widget {
    max-height: calc(100vh - 120px) !important; // Dar espacio para la navbar
    max-width: calc(100vw - 32px) !important;
  }
}
</style>
