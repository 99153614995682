<template>
  <div class="chat-widget-container">
    <!-- Botón flotante cuando está cerrado -->
    <md-button v-if="!isOpen" @click="toggleChat" class="md-fab md-primary chat-fab"
      :class="{ 'has-notification': hasNewMessages }">
      <md-icon>chat</md-icon>
    </md-button>

    <!-- Chat expandido -->
    <transition name="chat-slide">
      <md-card v-if="isOpen" class="chat-widget">
        <md-card-header>
          <div class="md-title">
            <md-avatar class="md-avatar-icon md-primary">
              <md-icon>smart_toy</md-icon>
            </md-avatar>
            <span>Asistente IA</span>
            <md-button class="md-icon-button md-dense close-button" @click="toggleChat">
              <md-icon>close</md-icon>
            </md-button>
          </div>
        </md-card-header>

        <md-card-content>
          <div class="chat-messages" ref="messageContainer">
            <transition-group name="message-fade">
              <div v-for="message in messages" :key="message.id" :class="['message-wrapper', message.type]">
                <md-card :class="['message-bubble', `md-${message.type}`]" md-with-hover>
                  <md-card-content>
                    {{ message.text }}
                    <span class="message-time">{{ formatTime(message.timestamp) }}</span>
                  </md-card-content>
                </md-card>
              </div>
            </transition-group>
            <div v-if="isTyping" class="typing-indicator">
              <div class="typing-dot"></div>
              <div class="typing-dot"></div>
              <div class="typing-dot"></div>
            </div>
          </div>
        </md-card-content>

        <md-divider></md-divider>

        <md-card-actions>
          <div v-if="suggestions.length > 0" class="suggestions-container">
            <md-chip v-for="suggestion in suggestions" :key="suggestion" class="md-primary"
              @click="userInput = suggestion; sendMessage()">
              {{ suggestion }}
            </md-chip>
          </div>
          <div class="input-container">
            <md-field>
              <md-input v-model="userInput" @keyup.enter="sendMessage" placeholder="Escribe un mensaje..."
                :disabled="isTyping"></md-input>
            </md-field>
            <md-button class="md-primary md-raised" @click="sendMessage" :disabled="!userInput.trim() || isTyping">
              <md-icon>send</md-icon>
            </md-button>
          </div>
        </md-card-actions>
      </md-card>
    </transition>
  </div>
</template>

<script>


import { mapState } from 'vuex'
export default {
  name: 'AIChatWidget',
  props: {
    userName: {
      type: String,
      default: ''
    },
    userAvatar: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapState({
      chatMessage: state => state.chat.message,
      shouldOpen: state => state.chat.isOpen
    })
  },
  data() {
    return {
      messages: [],
      userInput: '',
      isTyping: false,
      isOpen: false,
      hasNewMessages: false,
      chatContext: [], // Agregar el contexto del chat
      suggestions: [], // Agregar las sugerencias
    }
  },
  methods: {
    toggleChat() {
      this.isOpen = !this.isOpen
      if (this.isOpen) {
        this.hasNewMessages = false
        this.$nextTick(() => {
          this.scrollToBottom()
        })
      }
    },
    openChat() {
      this.isOpen = true;
      this.hasNewMessages = false;
    },
    async sendMessage() {
      if (!this.userInput.trim() || this.isTyping) return;

      const userMessage = this.userInput;
      this.userInput = '';

      // Mensaje usuario
      this.messages.push({
        id: Date.now(),
        type: 'user',
        text: userMessage,
        timestamp: new Date()
      });

      this.isTyping = true;
      await this.scrollToBottom();

      try {
        // Usar Vuex
        const response = await this.$store.dispatch('processUnifiedSearch', userMessage);

        this.messages.push({
          id: Date.now() + 1,
          type: 'bot',
          text: response.content,
          timestamp: new Date()
        });

        this.suggestions = response.suggestions || [];

      } catch (error) {
        console.error('Error:', error);
        this.messages.push({
          id: Date.now() + 1,
          type: 'bot',
          text: 'Error procesando mensaje. Intenta nuevamente.',
          timestamp: new Date()
        });
      } finally {
        this.isTyping = false;
        await this.$nextTick();
        this.scrollToBottom();
      }
    },

    async getAIResponse(message) {
      return ServChat.send(message)
        .then((response) => {
          console.log('Respuesta de IA:', response);
          console.log('Respuesta de IA:', response.data);
          return response.data;
        })
        .catch((error) => {
          console.error('Error en getAIResponse:', error);
          throw error;
        });
    },

    formatTime(date) {
      return new Date(date).toLocaleTimeString('es-ES', {
        hour: '2-digit',
        minute: '2-digit'
      })
    },

    scrollToBottom() {
      return new Promise(resolve => {
        setTimeout(() => {
          if (this.$refs.messageContainer) {
            this.$refs.messageContainer.scrollTop = this.$refs.messageContainer.scrollHeight
          }
          resolve()
        }, 100)
      })
    },
  },
  mounted() {
    // Mensaje inicial
    setTimeout(() => {
      this.messages.push({
        id: Date.now(),
        type: 'bot',
        text: '¡Hola! ¿En qué puedo ayudarte hoy?',
        timestamp: new Date()
      })
      this.hasNewMessages = true
    }, 1000)
  },
  watch: {
    shouldOpen: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.isOpen = true;
          this.$store.commit('SET_CHAT_OPEN', false);
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-widget-container {
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 1000;
}

.chat-fab {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .md-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 0;
  }

  &.has-notification::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 12px;
    height: 12px;
    background: red;
    border-radius: 50%;
    border: 2px solid white;
  }
}

.chat-widget {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 350px;
  height: 500px;
  display: flex;
  flex-direction: column;
  margin: 0;

  .md-card-header {
    padding: 8px 16px;
    position: sticky;
    top: 0;
    background: white;
    z-index: 2;

    .md-title {
      display: flex;
      align-items: center;
      gap: 12px;
      margin: 0;

      span {
        flex: 1;
      }
    }
  }

  .close-button {
    margin: -8px;
  }

  .md-card-content {
    flex: 1;
    overflow: hidden;
    padding: 0;
    position: relative;
  }

  .md-card-actions {
    position: sticky;
    bottom: 0;
    background: white;
    z-index: 2;
    padding: 8px 0;
    margin: 0;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  }
}

.chat-messages {
  height: 100%;
  overflow-y: auto;
  padding: 16px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-overflow-scrolling: touch;
  /* Para mejor scroll en iOS */
  scroll-behavior: smooth;
  /* Scroll suave */

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.message-wrapper {
  margin: 8px 0;
  display: flex;

  &.user {
    justify-content: flex-end;
  }
}

.message-bubble {
  max-width: 70%;
  margin: 4px 0;

  .md-card-content {
    padding: 8px 16px;
    position: relative;
  }

  &.md-bot {
    background: var(--md-theme-default-primary);
    color: white;
  }

  &.md-user {
    background: var(--md-theme-default-accent);
    color: white;
  }
}

.message-time {
  font-size: 0.7em;
  opacity: 0.7;
  position: absolute;
  bottom: 4px;
  right: 8px;
}

.suggestions-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  .md-chip {
    cursor: pointer;
    transition: transform 0.2s ease;

    &:hover {
      transform: scale(1.05);
    }
  }
}

.input-container {
  display: flex;
  width: 100%;
  padding: 8px 16px;
  gap: 8px;
  align-items: center;

  .md-field {
    flex: 1;
    margin: 0;
    padding-top: 0;
  }
}

// Animaciones del chat
.chat-slide-enter-active,
.chat-slide-leave-active {
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.chat-slide-enter-from,
.chat-slide-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

// Animaciones de mensajes
.message-fade-enter-active,
.message-fade-leave-active {
  transition: all 0.3s ease;
}

.message-fade-enter-from,
.message-fade-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

// Indicador de escritura
.typing-indicator {
  display: flex;
  gap: 4px;
  padding: 16px;

  .typing-dot {
    width: 8px;
    height: 8px;
    background: var(--md-theme-default-primary);
    border-radius: 50%;
    animation: typing 1s infinite ease-in-out;

    &:nth-child(1) {
      animation-delay: 0s;
    }

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
    }
  }
}

@keyframes typing {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

// Ajustes responsive
@media (max-width: 600px) {
  .chat-widget {
    width: calc(100vw - 48px);
    height: calc(100vh - 96px);
    position: fixed;
    top: 24px;
    left: 24px;
    right: 24px;
    bottom: 24px;
  }
}
</style>