<template>
  <div class="md-layout">
    <div
      class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-100"
    >
      <div class="md-layout-item md-small-size-100">
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-primary">
            <div class="card-icon">
              <md-icon>mail_outline</md-icon>
            </div>
            <h4 class="title">Arrendador</h4>
          </md-card-header>
          <md-card-content>
            <div class="md-layout-item md-size-100 md-medium-size-100">
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <div class="md-layout">
                  <div class="md-layout-item md-size-50 md-medium-size-100">
                    
                    <md-field :class="messageClass">
                      <label >*Seleccione el Tipo de Póliza </label>
                      <md-select v-model="solicitudTipoPoliza">
                        <md-option value="Tradicional">Tradicional</md-option>
                        <md-option value="Plus">Plus</md-option>
                      </md-select>
                      <span class="md-error">Campo Requerido</span>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-medium-size-100">                   
                    <md-field :class="messageClass" >
                      <label>*Seleccione Tipo de Regimen</label>
                      <md-select v-model="solicitudTipoRegimen" name="regimen">
                        <md-option value="1">Fisica</md-option>
                        <md-option value="2">Moral</md-option>
                      </md-select>
                    </md-field>
                  </div>
                </div>
              </div>
              <div
                class="md-layout-item md-size-100 md-medium-size-100"
                v-if="solicitudTipoRegimen == 1"
              >
                <div class="md-layout">
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field :class="messageClass" >
                      <label>*Nombre(s)</label>
                      <md-input :disabled="permisos()" v-model="solicitudNombreProp"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field :class="messageClass" >
                      <label>*Apellido Paterno</label>
                      <md-input v-model="solicitudApePaternoProp"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field :class="messageClass" >
                      <label>Apellido Materno</label>
                      <md-input v-model="solicitudApeMaternoProp"></md-input>
                    </md-field>
                  </div>
                </div>
              </div>
              <div
                class="md-layout-item md-size-100 md-medium-size-100"
                v-if="solicitudTipoRegimen == 2"
              >
                <div class="md-layout">
                  <div class="md-layout-item md-size-50 md-medium-size-100">
                    <md-field :class="messageClass" >
                      <label>*Razón Social</label>
                      <md-input v-model="solicitudRazonSocial"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-medium-size-100">
                    <md-field :class="messageClass" >
                      <label>*RFC</label>
                      <md-input v-model="solicitudRfc"></md-input>
                    </md-field>
                  </div>
                </div>
              </div>
              <div
                class="md-layout-item md-size-100 md-medium-size-100"
                v-if="solicitudTipoRegimen == 2"
              >
                <div class="md-layout">
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field :class="messageClass">
                      <label>*Nombre del Representante Legal</label>
                      <md-input
                        v-model="solicitudRepresentanteLegal"
                      ></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field :class="messageClass">
                      <label>*Apellido Paterno</label>
                      <md-input v-model="solicitudApePaternoLegal"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field :class="messageClass">
                      <label>*Apellido Materno</label>
                      <md-input v-model="solicitudApeMaternoLegal"></md-input>
                    </md-field>
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <div class="md-layout">
                  <div class="md-layout-item md-size-50 md-medium-size-100">
                    <md-field :class="messageClass">
                      <label
                        >*Domicilio actual (calle, No. exterior y/o No.
                        interior)</label
                      >
                      <md-input v-model="solicitudDomicilioProp"></md-input>
                      <span class="md-error">Campo Requerido</span>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-medium-size-100">
                    <md-field :class="messageClass">
                      <label>*Colonia</label>
                      <md-input v-model="coloniaActual"></md-input>
                    </md-field>
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <div class="md-layout">
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field :class="messageClass">
                      <label>*Alcaldía o Municipio</label>
                      <md-input v-model="alcaldiaMunicipioActual"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field :class="messageClass">
                      <label>*Código Postal</label>
                      <md-input v-model="codigoPostalActual"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field :class="messageClass">
                      <label for="EstadoActual">*Estado</label>
                      <md-input v-model="estadoActual"></md-input>
                    </md-field>
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <div class="md-layout">
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field :class="messageClass">
                      <label>*Nacionalidad</label>
                      <md-input v-model="solicitudNacionalidad"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field :class="messageClass">
                      <label>Teléfono</label>
                      <md-input v-model="solicitudTelefonoProp"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field :class="messageClass">
                      <label>*Celular</label>
                      <md-input v-model="solicitudCelularProp"></md-input>
                    </md-field>
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <div class="md-layout">
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field>
                      <label>E-mail</label>
                      <md-input v-model="solicitudEmailProp"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field>
                      <label>Tipo de Identificación</label>
                      <md-input v-model="tipoIdentificacion"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field>
                      <label>Número de Identificación</label>
                      <md-input v-model="numeroIdentificacion"></md-input>
                    </md-field>
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <label :class="{ 'error' : isBandera }">*Para el pago de rentas:</label>
                <div class="md-layout-item md-size-100 md-medium-size-100">
                  <div>
                    <md-radio v-model="SolicitudTipoDeposito" value="1"
                      >Depósito y/o transferencia bancaria</md-radio
                    >

                    <md-radio v-model="SolicitudTipoDeposito" value="2"
                      >En el domicilio del arrendador y/o cobro en el
                      inmueble</md-radio
                    >
                  </div>
                </div>
              </div>
              <div
                class="md-layout-item md-size-100 md-medium-size-100"
                v-if="SolicitudTipoDeposito == 1"
              >
                <div class="md-layout">
                  <div class="md-layout-item md-size-70 md-medium-size-100">
                    <md-field :class="messageClass">
                      <label>*Titular de la cuenta</label>
                      <md-input v-model="SolicitudNombreCuenta"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-30 md-medium-size-100">
                    <md-field :class="messageClass">
                      <label>*Banco</label>
                      <md-input v-model="SolicitudBanco"></md-input>
                    </md-field>
                  </div>
                </div>
              </div>
              <div
                class="md-layout-item md-size-100 md-medium-size-100"
                v-if="SolicitudTipoDeposito == 1"
              >
                <div class="md-layout">
                  <div class="md-layout-item md-size-50 md-medium-size-100">
                    <md-field :class="messageClass">
                      <label>*No. Cuenta</label>
                      <md-input v-model="SolicitudCuenta"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-medium-size-100">
                    <md-field :class="messageClass">
                      <label>*CLABE</label>
                      <md-input v-model="SolicitudClabe"></md-input>
                    </md-field>
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <div class="md-layout">
                  <div class="md-layout-item md-size-70 md-medium-size-100">
                    <md-field :class="messageClass">
                      <label
                        >*Domicilio del bien a ser arrendado (Calle, No.
                        Exterior y/o No. Interior)</label
                      >
                      <md-input
                        v-model="solicitudUbicacionArrendado"
                      ></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-30 md-medium-size-100">
                    <md-field :class="messageClass">
                      <label>*Colonia</label>
                      <md-input v-model="coloniaArrendar"></md-input>
                    </md-field>
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <div class="md-layout">
                  <div class="md-layout-item md-size-50 md-medium-size-100">
                    <md-field :class="messageClass">
                      <label>*Alcaldía o Municipio</label>
                      <md-input v-model="alcaldiaMunicipioArrendar"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-medium-size-100">
                    <md-field :class="messageClass">
                      <label>*Código Postal</label>
                      <md-input v-model="codigoPostalArrendar"></md-input>
                    </md-field>
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <div class="md-layout">
                  <div class="md-layout-item md-size-50 md-medium-size-100">
                    <md-field :class="messageClass">
                      <label for="estadoActual">*Estado</label>
                      <md-select v-model="estadosId">
                        <md-option
                          v-for="e in estadosCat"
                          :key="e.estadosId"
                          :value="e.estadosId"
                          >{{ e.estadoNombre }}</md-option
                        >
                      </md-select>
                    </md-field>
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <label>Arrendadores Adicionales (Opcional)</label>
                <div class="md-layout">
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field >
                      <label>Nombre (s)</label>
                      <md-input v-model="nombre1"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field>
                      <label>Apellido Paterno</label>
                      <md-input v-model="apePat1"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-34 md-medium-size-100">
                    <md-field>
                      <label>Apellido Materno</label>
                      <md-input v-model="apeMat1"></md-input>
                    </md-field>
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <div class="md-layout">
                  <div class="md-layout-item md-size-50 md-medium-size-100">
                    <md-field>
                      <label>Tipo de Identificación</label>
                      <md-input v-model="tipoIdent1"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-medium-size-100">
                    <md-field>
                      <label>Numero de Identificación</label>
                      <md-input v-model="numIdent1"></md-input>
                    </md-field>
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <label>Arrendadores 2 (Opcional)</label>
                <div class="md-layout">
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field>
                      <label>Nombre (s)</label>
                      <md-input v-model="nombre2"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field>
                      <label>Apellido Paterno</label>
                      <md-input v-model="apePat2"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-34 md-medium-size-100">
                    <md-field>
                      <label>Apellido Materno</label>
                      <md-input v-model="apeMat2"></md-input>
                    </md-field>
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <div class="md-layout">
                  <div class="md-layout-item md-size-50 md-medium-size-100">
                    <md-field>
                      <label>Tipo de Identificación</label>
                      <md-input v-model="tipoIdent2"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-medium-size-100">
                    <md-field>
                      <label>Numero de Identificación</label>
                      <md-input v-model="numIdent2"></md-input>
                    </md-field>
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <label>¿Cuándo le gustaría que firmaramos?</label>
                <div class="md-layout">
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <label>Fecha Firma</label>
                    <md-field>
                      <md-input
                        type="datetime-local"
                        clearable
                        placeholder="Fecha y Hora de firma"
                        v-model="solicitudFechaFirma"
                      >
                      </md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <label>Lugar para firmar</label>
                    <md-field>
                      <md-input v-model="solicitudLugarFirma"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <label>Fecha de solicitud</label>
                    <md-field>
                      <md-input
                        type="date"
                        clearable
                        placeholder="Hora Firma"
                        v-model="solicitudFechaSolicitud"
                      >
                      </md-input>
                    </md-field>
                  </div>
                </div>
                <label
                  ><strong>NOTA:</strong> Esta fecha y hora son tentativas hasta
                  que pase la investigación</label
                >
              </div>
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <label>Sección para datos de escrituras</label>
                <div class="md-layout">
                  <div class="md-layout-item md-size-25 md-medium-size-100">
                    <md-field>
                      <label># RPP const.</label>
                      <md-input v-model="numRppcons"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-25 md-medium-size-100">
                    <md-field>
                      <label># de Escritura</label>
                      <md-input v-model="escrituraNumero"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-25 md-medium-size-100">
                    <md-field>
                      <label>licenciado</label>
                      <md-input v-model="licenciado"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-25 md-medium-size-100">
                    <md-field>
                      <label># de Notaria</label>
                      <md-input v-model="numeroNotaria"></md-input>
                    </md-field>
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <div class="md-layout">
                  <div class="md-layout-item md-size-25 md-medium-size-100">
                    <md-field>
                      <label>Fecha de Reg. Const. RPP</label>
                      <md-input
                        type="date"
                        clearable
                        placeholder="Fecha Inicio"
                        v-model="fechaRppcons"
                      >
                      </md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-25 md-medium-size-100">
                    <md-field>
                      <label># de Escritura del Poder</label>
                      <md-input v-model="numEscPoder"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-25 md-medium-size-100">
                    <md-field>
                      <label>Titular de la notaria que emite el poder</label>
                      <md-input v-model="titularNotaPoder"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-25 md-medium-size-100">
                    <md-field>
                      <label># de la notaria</label>
                      <md-input v-model="numNotaria"></md-input>
                    </md-field>
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <div class="md-layout">
                  <div class="md-layout-item md-size-50 md-medium-size-100">
                    <md-field>
                      <label>Fecha emite el Poder</label>
                      <md-input
                        type="date"
                        clearable
                        placeholder="Fecha Inicio"
                        v-model="fechaEmitePoder"
                      >
                      </md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-medium-size-100">
                    <md-field>
                      <label>Fecha Constitutiva</label>
                      <md-input
                        type="date"
                        clearable
                        placeholder="Fecha Inicio"
                        v-model="fechaConstitutiva"
                      >
                      </md-input>
                    </md-field>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
        </md-card>
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-primary">
            <div class="card-icon">
              <md-icon>mail_outline</md-icon>
            </div>
            <h4 class="title">Arrendatario</h4>
          </md-card-header>
          <md-card-content>
            <div>
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <label>¿A quien se realiza el recibo de pago de póliza?:</label>
                <div class="md-layout">
                  <div class="md-layout-item md-size-100 md-medium-size-100">
                    <md-radio v-model="solicitudRecibodePago" value="Arrendador"
                      >Propietario</md-radio
                    >
                    <md-radio
                      v-model="solicitudRecibodePago"
                      value="Arrendatario"
                      >Arrendatario</md-radio
                    >
                    <md-radio
                      v-model="solicitudRecibodePago"
                      value="50% Arrendador y 50% Arrendatario"
                      >50% Propietario y 50% Arrendatario</md-radio
                    >
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <div class="md-layout">
                  <div class="md-layout-item md-size-25 md-medium-size-100">
                    <md-field :class="messageClass">
                      <label>*Importe mensual de renta</label>
                      <md-input v-model="solicitudImporteMensual"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-25 md-medium-size-100">
                    <md-field :class="messageClass">
                      <label>*Depósito en garantía</label>
                      <md-input v-model="solicitudDepositoGarantia"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-25 md-medium-size-100">
                    <md-field>
                      <label>Cuota de mantenimiento</label>
                      <md-input v-model="solicitudCuotaMant"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-25 md-medium-size-100">
                    <div class="md-layout-item md-size-25 md-medium-size-100">
                      <md-checkbox v-model="solicitudIncluidaRenta"
                        >¿Incluida en la renta?</md-checkbox
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <div class="md-layout">
                  <div class="md-layout-item md-size-25 md-medium-size-100">
                    <md-checkbox v-model="solicitudPagare"
                      >Necesita pagarés</md-checkbox
                    >
                  </div>
                  <div class="md-layout-item md-size-25 md-medium-size-100">
                    <md-checkbox v-model="solicitudFiador"
                      >¿Necesita fiador?</md-checkbox
                    >
                  </div>
                  <div class="md-layout-item md-size-25 md-medium-size-100">
                    <md-checkbox v-model="solicitudInmuebleGaran"
                      >¿Necesita inmueble en garantía?</md-checkbox
                    >
                  </div>
                  <div class="md-layout-item md-size-25 md-medium-size-100">
                    <md-checkbox v-model="solicitudCartaEntrega"
                      >¿Necesita carta Entrega?
                    </md-checkbox>
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <div class="md-layout">
                  <div class="md-layout-item md-size-50 md-medium-size-100">
                    <label>Vigencia de la Póliza del:</label>
                    <md-field>
                      <md-input
                        type="date"
                        class="mb-3"
                        clearable
                        placeholder="Fecha Inicio"
                        v-model="solicitudVigenciaContratoI"
                      >
                      </md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-50 md-medium-size-100">
                    <label>AL:</label>
                    <md-field>
                      <md-input
                        type="date"
                        class="mb-3"
                        clearable
                        placeholder="Fecha Inicio"
                        v-model="solicitudVigenciaContratoF"
                      >
                      </md-input>
                    </md-field>
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <div class="md-layout">
                  <div class="md-layout-item md-size-50 md-medium-size-100">
                    <md-field :class="messageClass">
                      <label for="tipoInmueble">*Tipo de inmueble</label>
                      <md-select
                        v-model="tipoInmobiliarioId"
                        name="tipoInmueble"
                      >
                        <md-option
                          v-for="tipo in tipoInmueble"
                          :key="tipo.tipoInmobiliarioId"
                          :value="tipo.tipoInmobiliarioId"
                          >{{ tipo.tipoInmobiliarioDesc }}</md-option
                        >
                      </md-select>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-25 md-medium-size-100">
                    <md-checkbox v-model="solicitudAmueblado"
                      >¿Amueblado?</md-checkbox
                    >
                  </div>
                  <div class="md-layout-item md-size-50 md-medium-size-100">
                    <md-field>
                      <label>Observaciones</label>
                      <md-textarea
                        v-model="solicitudObservaciones"
                      ></md-textarea>
                    </md-field>
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <label>Datos del Arrendatario (Son para contactarlo)</label>
                <div class="md-layout">
                  <div class="md-layout-item md-size-34 md-medium-size-100">
                    <md-field :class="messageClass">
                      <label>*Seleccione Tipo de Régimen</label>
                      <md-select v-model="tipoArrendatario">
                        <md-option value="1">Fisica</md-option>
                        <md-option value="2">Moral</md-option>
                      </md-select>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field :class="messageClass">
                      <label>*Teléfono</label>
                      <md-input v-model="arrendatarioTelefono"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field>
                      <label>Arrendatario Correo</label>
                      <md-input v-model="arrendatarioCorreo"></md-input>
                    </md-field>
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <div class="md-layout">
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field :class="messageClass">
                      <label>*Arrendatario Nombre(s)</label>
                      <md-input v-model="arrendatarioNombre"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field :class="messageClass">
                      <label>*Apellido Paterno</label>
                      <md-input v-model="arrendatarioApePat"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field>
                      <label>Apellido Materno</label>
                      <md-input v-model="arrendatarioApeMat"></md-input>
                    </md-field>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
        </md-card>
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-primary">
            <div class="card-icon">
              <md-icon>mail_outline</md-icon>
            </div>
            <h4 class="title">Personal Inmobiliario</h4>
          </md-card-header>
          <md-card-content>
            <div>
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <div class="md-layout">
                  <div class="md-layout-item md-size-45 md-medium-size-100">
                    <md-field :class="messageClass">
                      <label>*Representante</label>
                      <md-input
                        v-model="representanteNombre"
                        :disabled="true"
                      ></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-45 md-medium-size-100">
                    <md-field :class="messageClass">
                      <label>*Representación</label>
                      <md-input
                        v-model="representacion"
                        :disabled="true"
                      ></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-10 md-medium-size-10">
                    <md-button
                      class="md-primary"
                      @click.native="representante()"
                      ><md-icon>search</md-icon></md-button
                    >
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <div class="md-layout">
                  <div class="md-layout-item auto-size md-medium-size-100">
                    <md-field>
                      <label>AsesorNombre</label>
                      <md-input
                        v-model="asesorNombre"
                      ></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item auto-size md-medium-size-100">
                    <md-field>
                      <label>Asesor Apellido Paterno</label>
                      <md-input
                        v-model="asesorApePaterno"
                      ></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item auto-size md-medium-size-100">
                    <md-field>
                      <label>Asesor Apellido Materno</label>
                      <md-input
                        v-model="asesorApeMaterno"
                      ></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item auto-size md-medium-size-100">
                    <md-field>
                      <label>Inmobiliaria</label>
                      <md-input
                        v-model="inmobiliaria"                       
                      ></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-10 md-medium-size-100">
                    <md-button class="md-primary" @click.native="asesor()"
                      ><md-icon>search</md-icon></md-button
                    >
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <div class="md-layout">
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field>
                      <label>Teléfono</label>
                      <md-input
                        v-model="solicitudTelefono"
                      ></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field>
                      <label>Celular</label>
                      <md-input
                        v-model="solicitudCelular"
                      ></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-33 md-medium-size-100">
                    <md-field>
                      <label>E-mail</label>
                      <md-input v-model="solicitudEmail"></md-input>
                    </md-field>
                  </div>
                </div>
              </div>
              <div class="md-layout-item md-size-100 md-medium-size-100">
                <div class="md-layout">
                  <div class="md-layout-item md-size-100 md-medium-size-100">
                    <md-checkbox v-model="solicitudAdmiInmueble"
                      >Es propietario:
                    </md-checkbox>
                    <md-checkbox v-model="solicitudEsAdminInmueble"
                      >Es administrador de inmuebles:
                    </md-checkbox>
                    <md-checkbox v-model="isRenovacion"
                      >Es renovación:
                    </md-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <md-button class="md-default" @click.native="Regresamos()"
              >Cancelar</md-button
            >
            <md-button
              class="md-primary"
              @click.native="postNow()"
              :disabled="permisos()"
              v-if="this.visible == false"
              >Guardar</md-button>
              <md-button v-if="this.visible == true" class="md-primary" disabled>
                <div class="loader"></div>
              </md-button>
          </md-card-actions>
        </md-card>
        <modal v-if="classicModal" @close="classicModalHide">
          <template slot="header">
            <md-button
              class="md-simple md-just-icon md-round modal-default-button"
              @click="classicModalHide"
            >
              <md-icon>clear</md-icon>
            </md-button>
          </template>
          <template v-if="modaltipo == 1" slot="body">
            <div class="modal-body">
              <md-card>
                <md-card-header
                  class="md-card-header-icon md-card-header-primary"
                >
                  <div class="card-icon">
                    <md-icon>assignment</md-icon>
                  </div>
                  <h4 class="title">Representantes</h4>
                </md-card-header>
                <md-card-content>
                  <md-table
                    :value="queriedData"
                    :md-sort.sync="currentSort"
                    :md-sort-order.sync="currentSortOrder"
                    :md-sort-fn="customSort"
                    class="paginated-table table-striped table-hover"
                  >
                    <md-table-toolbar>
                      <md-field>
                        <label for="pages">Por página</label>
                        <md-select v-model="pagination.perPage" name="pages">
                          <md-option
                            v-for="item in pagination.perPageOptions"
                            :key="item"
                            :label="item"
                            :value="item"
                          >
                            {{ item }}
                          </md-option>
                        </md-select>
                      </md-field>

                      <md-field>
                        <md-input
                          type="search"
                          class="mb-3"
                          clearable
                          style="width: 200px"
                          placeholder="Buscar registro"
                          v-model="searchQuery"
                        ></md-input>
                      </md-field>
                    </md-table-toolbar>
                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                      <md-table-cell md-label="Representante">
                        {{ item.usuarioNomCompleto }}
                      </md-table-cell>
                      <md-table-cell md-label="Representación">
                        {{ item.representacionNombre }}
                      </md-table-cell>
                      <md-table-cell md-label="Seleccion"
                        ><md-checkbox
                          v-model="item.seleccionado"
                          @change="SeleccionTablaRepresentante(item)"
                      /></md-table-cell>
                    </md-table-row>
                  </md-table>
                </md-card-content>
                <md-card-actions md-alignment="space-between">
                  <div class="">
                    <p class="card-category">
                      Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                    </p>
                  </div>
                  <pagination
                    class="pagination-no-border pagination-success"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="total"
                  >
                  </pagination>
                </md-card-actions>
              </md-card>
            </div>
          </template>
          <template v-if="modaltipo == 2" slot="body">
            <div class="modal-body">
              <md-card>
                <md-card-header
                  class="md-card-header-icon md-card-header-primary"
                >
                  <div class="card-icon">
                    <md-icon>assignment</md-icon>
                  </div>
                  <h4 class="title">Asesores</h4>
                </md-card-header>
                <md-card-content>
                  <md-table
                    :value="queriedData2"
                    :md-sort.sync="currentSort"
                    :md-sort-order.sync="currentSortOrder"
                    :md-sort-fn="customSort2"
                    class="paginated-table table-striped table-hover"
                  >
                    <md-table-toolbar>
                      <md-field>
                        <label for="pages">Por página</label>
                        <md-select v-model="pagination.perPage" name="pages">
                          <md-option
                            v-for="item in pagination.perPageOptions"
                            :key="item"
                            :label="item"
                            :value="item"
                          >
                            {{ item }}
                          </md-option>
                        </md-select>
                      </md-field>

                      <md-field>
                        <md-input
                          type="search"
                          class="mb-3"
                          clearable
                          style="width: 200px"
                          placeholder="Buscar registro"
                          v-model="search"
                        ></md-input>
                      </md-field>
                    </md-table-toolbar>
                    <md-table-row slot="md-table-row" slot-scope="{ item }">
                      <md-table-cell md-label="Asesor">{{
                        item.usuarioNomCompleto
                      }}</md-table-cell>
                      <md-table-cell md-label="Inmobiliaria">{{
                        item.usuarioInmobiliaria
                      }}</md-table-cell>
                      <md-table-cell md-label="Seleccion"
                        ><md-checkbox
                          v-model="item.seleccionado2"
                          @change="SeleccionTablaAsesor(item)"
                      /></md-table-cell>
                    </md-table-row>
                  </md-table>
                </md-card-content>
                <md-card-actions md-alignment="space-between">
                  <div class="">
                    <p class="card-category">
                      Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                    </p>
                  </div>
                  <pagination
                    class="pagination-no-border pagination-success"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total="total"
                  >
                  </pagination>
                </md-card-actions>
              </md-card>
            </div>
          </template>
        </modal>
      </div>
    </div>
  </div>
</template>

<script>
import servRep from "@/Servicios/Usuarios/usuario.js";
import servAses from "@/Servicios/Usuarios/usuario.js";
import { Pagination, Modal } from "@/components";
import servSolicitud from "@/Servicios/Solicitudes/solicitudes.js";
import servTipoInm from "@/Servicios/Solicitudes/tipoInmueble.js";
import servEstados from "@/Servicios/Solicitudes/estados.js";
import mensaje from "../../Servicios/ManjeadorErrores/Mensajes";
import Swal from "sweetalert2";
import Fuse from "fuse.js";
import store from "@/Store/index";
//import { disable } from "aws-amplify/analytics";

export default {
  computed: {
    messageClass() {
        return {
          'md-error': this.isBandera
        }
      },
    usuario() {
      return store.state.usuario;
    },
    general() {
      return store.state.General;
    },
    modoPantalla() {
      return store.state.Modo;
    },
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    queriedData2() {
      let result = this.tableData2;
      if (this.dataSearched.length > 0) {
        result = this.dataSearched;
      }
      return result.slice(this.from2, this.to2);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    to2() {
      let highBound = this.from2 + this.pagination.perPage;
      if (this.total2 < highBound) {
        highBound = this.total2;
      }
      return highBound;
    },
    from2() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
    total2() {
      return this.dataSearched.length > 0
        ? this.dataSearched.length
        : this.tableData2.length;
    },
  },
  name: 'ErrorsMessages',
  components: {
    Pagination,
    Modal,
  },

  data() {
    return {
      currentSort: "usuarioNomCompleto",
      currentSortOrder: "asc",
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      isBandera: false,
      footerTable: [],
      areaId: 3,
      area2Id: 2,
      seleccionado: false,
      seleccionado2: false,
      searchQuery: "",
      search: "",
      propsToSearch: ["usuariosId", "usuarioNomCompleto", "usuarioNombre"],
      tableData: [],
      tableData2: [],
      searchedData: [],
      dataSearched: [],
      fuseSearch: null,
      searchFuse: null,
      modaltipo: 0,
      classicModal: false,
      solicitudId: 0,
      modo: 0,
      estadosCat: [],
      tipoInmueble: [],
      solicitudTipoPoliza: null,
      solicitudTipoRegimen: 0,
      solicitudNombreProp: "",
      solicitudApePaternoProp: "",
      solicitudApeMaternoProp: "",
      solicitudRazonSocial: "",
      solicitudRfc: "",
      solicitudRepresentanteLegal: "",
      solicitudApePaternoLegal: "",
      solicitudApeMaternoLegal: "",
      solicitudDomicilioProp: "",
      coloniaActual: "",
      alcaldiaMunicipioActual: "",
      codigoPostalActual: "",
      estadoActual: "",
      solicitudNacionalidad: "",
      solicitudTelefonoProp: "",
      solicitudCelularProp: "",
      solicitudEmailProp: "",
      tipoIdentificacion: "",
      numeroIdentificacion: "",
      solicitudUbicacionArrendado: "",
      coloniaArrendar: "",
      alcaldiaMunicipioArrendar: "",
      codigoPostalArrendar: "",
      Estados: "",
      estadosId: 0,
      nombre1: "",
      apePat1: "",
      apeMat1: "",
      tipoIdent1: "",
      numIdent1: "",
      nombre2: "",
      apePat2: "",
      apeMat2: "",
      tipoIdent2: "",
      numIdent2: "",
      SolicitudTipoDeposito: "",
      representanteNombre: "",
      representacion: "",
      asesorNombre: "",
      asesorApePaterno: "",
      asesorApeMaterno: "",
      inmobiliaria: "",
      solicitudTelefono: "",
      solicitudCelular: "",
      solicitudEmail: "",
      solicitudAdmiInmueble: false,
      solicitudEsAdminInmueble: false,
      isRenovacion: false,
      dateFormat: "mm/dd/aaaa",
      solicitudRecibodePago: "",
      solicitudImporteMensual: 0.0,
      solicitudDepositoGarantia: 0.0,
      solicitudCuotaMant: 0.0,
      solicitudIncluidaRenta: false,
      solicitudPagare: false,
      solicitudFiador: false,
      solicitudInmuebleGaran: false,
      solicitudCartaEntrega: false,
      solicitudAmueblado :false,
      solicitudVigenciaContratoI: "",
      solicitudVigenciaContratoF: "",
      tipoInmobiliarioId: 0,
      solicitudObservaciones: "",
      tipoArrendatario: "",
      arrendatarioNombre: "",
      arrendatarioApePat: "",
      arrendatarioApeMat: "",
      arrendatarioTelefono: "",
      arrendatarioCorreo: "",
      solicitudFechaFirma: "",
      solicitudHoraFirma: "",
      solicitudLugarFirma: "",
      solicitudFechaSolicitud: "",
      numRppcons: "",
      escrituraNumero: "",
      licenciado: "",
      numeroNotaria: "",
      fechaRppcons: "",
      numEscPoder: "",
      titularNotaPoder: "",
      numNotaria: "",
      fechaEmitePoder: "",
      fechaConstitutiva: "",
      sfisicaEmailJefe: "",
      SolicitudNombreCuenta: "",
      SolicitudBanco: "",
      SolicitudCuenta: "",
      SolicitudClabe: "",
      representanteid: 0,
      asesorid: 0,
      disableButton: false,
      visible : false,
    };
  },
  methods: {
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    customSort2(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort;
        if (this.currentSortOrder === "desc") {
          return a[sortBy].localeCompare(b[sortBy]);
        }
        return b[sortBy].localeCompare(a[sortBy]);
      });
    },
    //Metodo del boton cancelar de solicitudes

    Regresamos() {
      this.$router.push("../solicituds");
    },
    //logica para seleecionar representantes y asesores para los inputs

    SeleccionTablaRepresentante(item) {
      this.representanteNombre = item.usuarioNomCompleto;
      this.representacion = item.representacionNombre;
      this.representanteid = item.usuariosId;
      this.classicModalHide();
    },
    SeleccionTablaAsesor(item) {
      console.log("Asesor: ",item)
      this.asesorNombre = item.usuarioNombre;
      this.asesorApePaterno = item.usuarioApellidoPaterno;
      this.asesorApeMaterno = item.usuarioApellidoMaterno;
      this.inmobiliaria = item.usuarioInmobiliaria;
      this.asesorid = item.usuariosId;
      this.solicitudTelefono = item.usuarioTelefono;
      this.solicitudCelular = item.usuarioCelular;
      this.solicitudEmail = item.usurioEmail;
      this.classicModalHide();
    },

    //obtenemos los representantes para seleccionar en la tabla
    getRep() {
      servRep.getUsuarios(3).then((r) => {
        this.tableData = r.data;
        this.fuseSearch = new Fuse(this.tableData, {
          keys: ["usuariosId", "usuarioNomCompleto", "usuarioNombre", "representacionNombre"],
          threshold: 0.3,
        });
      });
    },
    //obtenemos los asesores para seleccionar en la tabla
    getAsesor() {
      servAses.getUsuarios(2).then((r) => {
        this.tableData2 = r.data;
        this.searchFuse = new Fuse(this.tableData2, {
          keys: ["usuariosId", "usuarioNomCompleto", "usuarioNombre", "usuarioApellidoPaterno", "usuarioApellidoMaterno"],
          threshold: 0.3,
        });
      });
    },
    //obetenemos los estados del combobox
    obtenerestados() {
      servEstados.getEstados().then((r) => {
        this.estadosCat = r.data;
      });
    },
    //formateamos la hora para que se muestre en el input
    horaVista(hora) {
      const horaFormateada = hora.substring(0, 16).replace(' ', 'T');
      return horaFormateada;
    },
    //procesamos la fecha para que el back la acepte
    procesamosFecha(fecha) {
      try {
        let fechaObj;
        if (!fecha) {
          fechaObj = new Date();
        } else {
          fechaObj = new Date(fecha);
        }
        let fechasbt = fechaObj.toISOString().substring(0, 10);
        return fechasbt;
      } catch (error) {
        console.error("Error al procesar la fecha:", error);
        throw error;
      }
    },
    fechaFirma() {
     const now = new Date();
     const futureDate = new Date();
     futureDate.setDate(now.getDate() + 2);
     let newDate = futureDate.toISOString().split('T')[0];
     return newDate;
    },
    // FUNCION PARA VALIDAR SI EL USUARIO PUEDE EDITAR O MOVER ALGO DEL LA SOLICIUD
    permisos(){
       let condicion = false;
       if (this.usuario.areaId == 7)
       {
         condicion = true;
       }
      return condicion;
    },
    getTipoInm() {
      servTipoInm.TipoInmueble().then((r) => {
        this.tipoInmueble = r.data;
      });
    },
    postNow() {
      //disableButton = true;
      this.visible = true;
      var data = {
        //IDs
        SolicitudId: this.solicitudId,
        EstadosId: this.estadosId,
        Creadorid: this.usuario.usuariosId,
        TipoInmobiliarioId: this.tipoInmobiliarioId,
        //Arrendador
        SolicitudNombreProp: this.solicitudNombreProp,
        SolicitudTipoPoliza: this.solicitudTipoPoliza,
        SolicitudApeMaternoProp: this.solicitudApeMaternoProp,
        SolicitudApePaternoProp: this.solicitudApePaternoProp,
        SolicitudDomicilioProp: this.solicitudDomicilioProp,
        ColoniaActual: this.coloniaActual,
        AlcaldiaMunicipioActual: this.alcaldiaMunicipioActual,
        CodigoPostalActual: this.codigoPostalActual,
        EstadoActual: this.estadoActual,
        SolicitudRazonSocial: this.solicitudRazonSocial,
        SolicitudRfc: this.solicitudRfc,
        SolicitudRepresentanteLegal: this.solicitudRepresentanteLegal,
        SolicitudApeMaternoLegal: this.solicitudApeMaternoLegal,
        SolicitudApePaternoLegal: this.solicitudApePaternoLegal,
        SolicitudNacionalidad: this.solicitudNacionalidad,
        SolicitudTelefonoProp: this.solicitudTelefonoProp,
        SolicitudCelularProp: this.solicitudCelularProp,
        SolicitudEmailProp: this.solicitudEmailProp,
        TipoIdentificacion: this.tipoIdentificacion,
        NumeroIdentificacion: this.numeroIdentificacion,
        SolicitudTipoDeposito: this.SolicitudTipoDeposito,
        SolicitudTipoRegimen: this.solicitudTipoRegimen,
        SolicitudNombreCuenta: this.SolicitudNombreCuenta,
        SolicitudBanco: this.SolicitudBanco,
        SolicitudCuenta: this.SolicitudCuenta,
        SolicitudClabe: this.SolicitudClabe,
        SolicitudUbicacionArrendado: this.solicitudUbicacionArrendado,
        ColoniaArrendar: this.coloniaArrendar,
        AlcaldiaMunicipioArrendar: this.alcaldiaMunicipioArrendar,
        CodigoPostalArrendar: this.codigoPostalArrendar,
        Nombre1: this.nombre1,
        ApePat1: this.apePat1,
        ApeMat1: this.apeMat1,
        TipoIdent1: this.tipoIdent1,
        NumIdent1: this.numIdent1,
        Nombre2: this.nombre2,
        ApePat2: this.apePat2,
        ApeMat2: this.apeMat2,
        TipoIdent2: this.tipoIdent2,
        NumIdent2: this.numIdent2,
        SolicitudFechaFirma: this.solicitudFechaFirma ? this.solicitudFechaFirma : this.fechaFirma(),
        SolicitudLugarFirma: this.solicitudLugarFirma,
        SolicitudFechaSolicitud: this.procesamosFecha(
          this.solicitudFechaSolicitud
        ),
        NumRppcons: this.numRppcons,
        EscrituraNumero: this.escrituraNumero,
        Licenciado: this.licenciado,
        NumeroNotaria: this.numeroNotaria,
        FechaRppcons: this.fechaRppcons,
        NumEscPoder: this.numEscPoder,
        TitularNotaPoder: this.titularNotaPoder,
        NumNotaria: this.numNotaria,
        FechaEmitePoder: this.fechaEmitePoder,
        FechaConstitutiva: this.fechaConstitutiva,
        //Arrendatario
        SolicitudRecibodePago: this.solicitudRecibodePago,
        SolicitudImporteMensual: this.solicitudImporteMensual,
        SolicitudDepositoGarantia: this.solicitudDepositoGarantia,
        SolicitudCuotaMant: this.solicitudCuotaMant,
        SolicitudIncluidaRenta: this.solicitudIncluidaRenta,
        SolicitudPagare: this.solicitudPagare,
        SolicitudFiador: this.solicitudFiador,
        SolicitudInmuebleGaran: this.solicitudInmuebleGaran,
        esAmueblado : this.solicitudAmueblado,
        SolicitudCartaEntrega: this.solicitudCartaEntrega,
        SolicitudVigenciaContratoI: this.procesamosFecha(
          this.solicitudVigenciaContratoI
        ),
        SolicitudVigenciaContratoF: this.procesamosFecha(
          this.solicitudVigenciaContratoF
        ),
        SolicitudObservaciones: this.solicitudObservaciones,
        TipoArrendatario: this.tipoArrendatario,
        ArrendatarioTelefono: this.arrendatarioTelefono,
        ArrendatarioCorreo: this.arrendatarioCorreo,
        ArrendatarioNombre: this.arrendatarioNombre,
        ArrendatarioApePat: this.arrendatarioApePat,
        ArrendatarioApeMat: this.arrendatarioApeMat,
        //Personal Inmobiliario
        Representanteid: this.representanteid,
        Inmobiliaria: this.inmobiliaria,
        Asesorid: this.asesorid,
        AsesorNombre: this.asesorNombre,
        AsesorApePaterno: this.asesorApePaterno,
        AsesorApeMaterno: this.asesorApeMaterno,
        SolicitudTelefono: this.solicitudTelefono,
        SolicitudCelular: this.solicitudCelular,
        SolicitudEmail: this.solicitudEmail,
        SolicitudAdmiInmueble: this.solicitudAdmiInmueble,
        SolicitudEsAdminInmueble: this.solicitudEsAdminInmueble,
        IsRenovacion: this.isRenovacion,
        //FisicaMoralId:this.fisicaMoralId,
      };
      if (this.solicitudId > 0) {
        console.log("response", data);
        servSolicitud
          .putSolicitud(this.solicitudId, data)
          .then((response) => {

            this.ProcesamosResponse(response.data);
          })
          .catch((e) => {
            mensaje.Error("SolicitudPost-crud", e);
          })
          .finllay(() => {
            this.disableButton = false;
          });
      } else {
        servSolicitud
          .post(data)
          .then((response) => {
            console.log("response", response.data);
            this.ProcesamosResponse(response.data);
          })
          .catch((e) => {
            mensaje.Error("SolicitudPost-crud", e);
          })
          .finllay(() => {
            this.disableButton = false;
          });
      }
    },
    representante() {
      this.modaltipo = 1;
      this.classicModal = true;
    },
    asesor() {
      this.modaltipo = 2;
      this.classicModal = true;
    },
    classicModalHide() {
      this.classicModal = false;
    },
    ProcesamosResponse(data) {
      data.forEach((e) => {
        let m = {
          mensaje: "",
          tipo: "",
        };
        switch (e.tipo) {
          case 5: //Atributos por completar
            this.general.SolicitudId = parseInt(e.mensaje);
            this.general.FisicaMoralId = 0;
            break;
          case 1: //Error
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            this.isBandera = true;
            this.visible = false;
            break;
          case 3: //success
            m.mensaje = e.mensaje;
            m.tipo = "success";
            this.notifyVue(m);
            this.visible = false;
            if (this.solicitudId == 0) {
              this.$router.push({ name: "operacion" });
            }
            break;
          case 2: //Information
            m.mensaje = e.mensaje;
            m.tipo = "danger";
            this.notifyVue(m);
            this.$router.push("../solicituds");
            break;
        }
      });
    },
    notifyVue(m) {
      this.$notify({
        message: m.mensaje,
        timeout: 30000,
        icon: "add_alert",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: m.tipo,
      });
    },
    getSolicitud() {
      servSolicitud.getId(this.solicitudId).then((r) => {
        this.loadData(r.data);
      });
    },
    loadData(item) {
      //IDs
      this.solicitudId = item.solicitudId;
      this.estadosId = item.estadosId;
      this.creadorid = item.creadorid;
      this.tipoInmobiliarioId = item.tipoInmobiliarioId;
      this.asesorid = item.asesorid;
      //Arrendador
      this.solicitudNombreProp = item.solicitudNombreProp;
      this.solicitudTipoPoliza = item.solicitudTipoPoliza;
      this.solicitudApeMaternoProp = item.solicitudApeMaternoProp;
      this.solicitudApePaternoProp = item.solicitudApePaternoProp;
      this.solicitudDomicilioProp = item.solicitudDomicilioProp;
      this.coloniaActual = item.coloniaActual;
      this.alcaldiaMunicipioActual = item.alcaldiaMunicipioActual;
      this.codigoPostalActual = item.codigoPostalActual;
      this.estadoActual = item.estadoActual;
      this.solicitudRazonSocial = item.solicitudRazonSocial;
      this.solicitudRfc = item.solicitudRfc;
      this.solicitudRepresentanteLegal = item.solicitudRepresentanteLegal;
      this.solicitudApeMaternoLegal = item.solicitudApeMaternoLegal;
      this.solicitudApePaternoLegal = item.solicitudApePaternoLegal;
      this.solicitudNacionalidad = item.solicitudNacionalidad;
      this.solicitudTelefonoProp = item.solicitudTelefonoProp;
      this.solicitudCelularProp = item.solicitudCelularProp;
      this.solicitudEmailProp = item.solicitudEmailProp;
      this.tipoIdentificacion = item.tipoIdentificacion;
      this.numeroIdentificacion = item.numeroIdentificacion;
      this.SolicitudTipoDeposito = item.solicitudTipoDeposito;
      this.solicitudTipoRegimen = item.solicitudTipoRegimen;
      this.SolicitudNombreCuenta = item.solicitudNombreCuenta;
      this.SolicitudBanco = item.solicitudBanco;
      this.SolicitudCuenta = item.solicitudCuenta;
      this.SolicitudClabe = item.solicitudClabe;
      this.solicitudUbicacionArrendado = item.solicitudUbicacionArrendado;
      this.coloniaArrendar = item.coloniaArrendar;
      this.alcaldiaMunicipioArrendar = item.alcaldiaMunicipioArrendar;
      this.codigoPostalArrendar = item.codigoPostalArrendar;
      this.nombre1 = item.nombre1;
      this.apePat1 = item.apePat1;
      this.apeMat1 = item.apeMat1;
      this.tipoIdent1 = item.tipoIdent1;
      this.numIdent1 = item.numIdent1;
      this.nombre2 = item.nombre2;
      this.apePat2 = item.apePat2;
      this.apeMat2 = item.apeMat2;
      this.tipoIdent2 = item.tipoIdent2;
      this.numIdent2 = item.numIdent2;
      this.solicitudFechaFirma = this.horaVista(item.solicitudFechaFirma);
      this.solicitudLugarFirma = item.solicitudLugarFirma;
      this.solicitudFechaSolicitud = this.procesamosFecha(
        item.solicitudFechaSolicitud
      );
      this.numRppcons = item.numRppcons;
      this.escrituraNumero = item.escrituraNumero;
      this.licenciado = item.licenciado;
      this.numeroNotaria = item.numeroNotaria;
      this.fechaRppcons = item.fechaRppcons;
      this.numEscPoder = item.numEscPoder;
      this.titularNotaPoder = item.titularNotaPoder;
      this.numNotaria = item.numNotaria;
      this.fechaEmitePoder = item.fechaEmitePoder;
      this.fechaConstitutiva = item.fechaConstitutiva;
      //Arrendatario
      this.solicitudRecibodePago = item.solicitudRecibodePago;
      this.solicitudImporteMensual = item.solicitudImporteMensual;
      this.solicitudDepositoGarantia = item.solicitudDepositoGarantia;
      this.solicitudCuotaMant = item.solicitudCuotaMant;
      this.solicitudIncluidaRenta = item.solicitudIncluidaRenta;
      this.solicitudPagare = item.solicitudPagare;
      this.solicitudFiador = item.solicitudFiador;
      this.solicitudInmuebleGaran = item.solicitudInmuebleGaran;
      this.solicitudAmueblado = item.esAmueblado;
      this.solicitudCartaEntrega = item.solicitudCartaEntrega;
      this.solicitudVigenciaContratoI = this.procesamosFecha(
        item.solicitudVigenciaContratoI
      );
      this.solicitudVigenciaContratoF = this.procesamosFecha(
        item.solicitudVigenciaContratoF
      );
      this.solicitudObservaciones = item.solicitudObservaciones;
      this.tipoArrendatario = item.tipoArrendatario;
      this.arrendatarioTelefono = item.arrendatarioTelefono;
      this.arrendatarioCorreo = item.arrendatarioCorreo;
      this.arrendatarioNombre = item.arrendatarioNombre;
      this.arrendatarioApePat = item.arrendatarioApePat;
      this.arrendatarioApeMat = item.arrendatarioApeMat;
      //Personal Inmobiliario
      this.representanteid = item.representanteid;
      this.representanteNombre = item.representante.usuarioNomCompleto;
      this.asesorNombre = item.asesor.usuarioNombre;
      this.asesorApeMaterno = item.asesor.usuarioApellidoMaterno;
      this.asesorApePaterno = item.asesor.usuarioApellidoPaterno;
      this.inmobiliaria = item.inmobiliaria;
      this.representacion = item.representante.representacion.representacionNombre;
      this.solicitudTelefono = item.asesor.usuarioTelefono;
      this.solicitudCelular = item.solicitudCelular;
      this.solicitudEmail = item.solicitudEmail;
      this.solicitudAdmiInmueble = item.solicitudAdmiInmueble;
      this.solicitudEsAdminInmueble = item.solicitudEsAdminInmueble;
      this.isRenovacion = item.isRenovacion;

      //FisicaMoralId=item.fisicaMoralId,
    },
  },
  created() {
    this.getTipoInm();
    this.obtenerestados();
    this.getRep();
    this.getAsesor();
    this.solicitudId = this.general.SolicitudId;
    if (this.solicitudId > 0) {
      this.getSolicitud();
    }
  },
  mounted() {},
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData;
      if (value !== "") {
        result = this.fuseSearch.search(this.searchQuery);
      }
      this.searchedData = result.map((r) => r.item);
    },
    search(value) {
      let result = this.tableData2;
      if (value !== "") {
        result = this.searchFuse.search(this.search);
      }
      this.dataSearched = result.map((r) => r.item);
    },
  },
};
</script>

<style lang="css" scoped>
.error {
  color: rgb(201, 4, 4);
}
/* HTML: <div class="loader"></div> */
.loader {
  width: 90px;
  height: 20px;
  font-size: 12px;
  clip-path: inset(0 2ch 0 0);
  animation: l4 1s steps(4) infinite;
}
.loader:before {
  content:"Guardando....."
}
@keyframes l4 {to{clip-path: inset(0 -1ch 0 0)}}
</style>
