import { http } from "@/http-commons";

class SearchService {
  searchComponent(query) {
    return http.post('/Assistant/process', {
      UserInput: query,  
      Context: []
    });
  }
}

export default new SearchService();