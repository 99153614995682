import { http } from "@/http-commons";

class flujoSolicitud {
    getFlujo(id){
        return http.get(`/FlujoSolicitud/${id}`);
    }
    post(data){
        return http.post('/FlujoSolicitud', data);
    }
    delete(id) {
        return http.delete(`/FlujoSolicitud/${id}`)
    }
    getUsuariosMatriz(){
        return http.get(`/FlujoSolicitud/MatrizUsuarios`)
    }
}

export default new flujoSolicitud();