import axios from "axios";

console.debug("Entorno:", process.env.VUE_APP_HTTP_API)
const token = localStorage.getItem('pjToken');

export const http = axios.create({
    baseURL: process.env.VUE_APP_HTTP_API,
    headers: {
        "Content-type": "application/json",
        "accept": "*/*",
        "Authorization": "Bearer " + token
    }
});

http.interceptors.request.use((config) => {
    const token = localStorage.getItem('pjToken');
    config.headers.Authorization = `Bearer ${token}`;
    return config;
}, (error) => {
    return Promise.reject(error);
});

http.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && error.response.status === 401) {
            // Limpiar el token y usuario
            localStorage.removeItem('pjToken');
            localStorage.removeItem('pjUser');
            
            // Redireccionar usando window.location
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);