<template>
  <md-toolbar md-elevation="0" class="md-transparent" :class="{
    'md-toolbar-absolute md-white md-fixed-top': $route.meta.navbarAbsolute,
  }">
    <div class="md-toolbar-row">
      <div class="md-toolbar-section-start">
        <md-button class="md-just-icon md-round md-simple md-toolbar-toggle" :class="{ toggled: $sidebar.showSidebar }"
          @click="toggleSidebar">
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
          <span class="icon-bar"></span>
        </md-button>
      </div>

      <!-- Contenedor central para el input de búsqueda -->
      <div class="search-container">
        <div class="search-wrapper">
          <input type="text" class="search-input" placeholder="¿Qué necesitas hacer hoy?" v-model="searchQuery"
            @keyup.enter="handleSearch" :disabled="isLoading" />
          <div class="search-loading" v-if="isLoading">
            <div class="loading-dots">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
          <div class="shine"></div>
        </div>
      </div>

      <div class="md-toolbar-section-end">
        <div class="md-collapse">
          <md-list>
            <md-list-item href="#/buscar">
              <md-icon>search</md-icon>
              <p>Buscar</p>
            </md-list-item>
            <md-list-item :href="'#/' + this.usuario.dashboard">
              <i class="material-icons">dashboard</i>
              <p class="hidden-lg hidden-md">Dashboard</p>
            </md-list-item>
            <md-list-item :href="'#/phone'">
              <i class="material-icons">phone</i>
              <p class="hidden-lg hidden-md">phone</p>
            </md-list-item>
            <md-list-item target="_blank" href="https://pj.youtrack.cloud/form/9c4facc8-2d2f-45c1-8647-3933cd75ea8b">
              <i class="material-icons">bug_report</i>
              <p class="hidden-lg hidden-md">bug_report</p>
            </md-list-item>
            <md-list-item href="#/user">
              <i class="material-icons">person</i>
              <p class="hidden-lg hidden-md">Profile</p>
            </md-list-item>
            <md-list-item @click="CerrarSesion()">
              <md-icon>lock_open</md-icon>
            </md-list-item>
          </md-list>
        </div>
      </div>
    </div>
  </md-toolbar>
</template>

<script>
import store from "@/Store/index";

export default {
  computed: {
    usuario() {
      return store.state.usuario;
    },
  },
  data() {
    return {
      searchQuery: '',
      isLoading: false,
      showModal: false,
    };
  },
  methods: {
    async handleSearch() {
      if (!this.searchQuery || this.isLoading) return;

      this.isLoading = true;
      try {
        await this.$store.dispatch('processUnifiedSearch', this.searchQuery);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        this.isLoading = false;
        this.searchQuery = '';
      }
    },
    CerrarSesion() {
      localStorage.clear();
      this.$router.push('Login');
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    }
  }
};
</script>

<style scoped>
.search-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.search-wrapper {
  position: relative;
  width: 100%;
  max-width: 600px;
  overflow: hidden;
}

.search-input {
  width: 100%;
  height: 40px;
  padding: 0 20px;
  border-radius: 20px;
  border: 2px solid rgba(175, 35, 39, 0.2);
  background: white;
  font-size: 1rem;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.search-input:focus {
  border-color: #AF2327;
  box-shadow: 0 4px 10px rgba(175, 35, 39, 0.2);
  outline: none;
}

.search-input::placeholder {
  color: #9A9A9A;
  font-style: italic;
}

.shine {
  position: absolute;
  top: 0;
  left: -100%;
  width: 50%;
  height: 100%;
  background: linear-gradient(120deg,
      transparent,
      rgba(255, 255, 255, 0.6),
      transparent);
  animation: shine 3s infinite;
}

@keyframes shine {
  0% {
    left: -100%;
  }

  20% {
    left: 100%;
  }

  100% {
    left: 100%;
  }
}

.search-loading {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.loading-dots {
  display: flex;
  gap: 4px;
}

.loading-dots span {
  width: 6px;
  height: 6px;
  background: #AF2327;
  border-radius: 50%;
  animation: bounce 0.5s alternate infinite;
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.16s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.32s;
}

@keyframes bounce {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-5px);
  }
}

.search-input:disabled {
  background-color: #f8f9fa;
  cursor: not-allowed;
}
</style>