import Vue from "vue";
import Vuex from "vuex";
import SearchService from "@/Servicios/IA/SearchService";

Vue.use(Vuex);

const store = new Vuex.Store({

  state: {
    token: null,
    General: {
      PolizaId: 0,
      Solicitud: null,
      SolucionId: 0,
      SolicitudId: 0,
      FisicaMoralId: 0,
    },
    Modo: 0,//1.- Nuevo, 2.- Edit, 3.- Update, 4.- Delete, 5.- Visualizar
    usuario: {
      activo: null,
      areaId: null,
      contraEncrypt: null,
      isResponsanle: null,
      representacionId: null,
      usuarioApellidoMaterno: null,
      usuarioApellidoPaterno: null,
      usuarioCelular: null,
      usuarioInmobiliaria: null,
      usuarioNomCompleto: null,
      usuarioNombre: null,
      usuarioPadreId: null,
      usuarioTelefono: null,
      usuariosId: null,
      usurioEmail: null,
      imagen: null,
      dashboard: null
    },
    cantidad: {
      soluciones: 0,
      polizas: 0,
      solicitudes: 0
    },
    Movimientos: {
      DetallePolizas: [],
      Transferencias: [],
      CuentasXPagar: [],
      CuentasXCobrar: []
    },
    Cuenta: {
      Id: 0,
      Nombre: null,
      Saldo: 0,
      entrada: 0,
      salida: 0,
      total: 0,
    },
    componenteBusqueda: {
      componente: null,
      parametros: {}
    },
    chat: {
      isOpen: false,
      message: null
    }
  },
  mutations: {
    SET_CHAT_OPEN(state, isOpen) {
      state.chat = { ...state.chat, isOpen };
    },
    SET_CHAT_MESSAGE(state, message) {
      state.chat = { ...state.chat, message };
    },
    ESTABLECER_COMPONENTE_BUSQUEDA(state, { componente, parametros }) {
      state.componenteBusqueda.componente = componente;
      state.componenteBusqueda.parametros = parametros;
    }
  },
  actions: {
    guardarComponenteBusqueda({ commit }, { componente, parametros }) {
      commit('ESTABLECER_COMPONENTE_BUSQUEDA', { componente, parametros });
    },
    async processUnifiedSearch({ commit }, query) {
      try {
        const response = await SearchService.searchComponent(query);
        const data = response.data;

        if (data.action === 'response') {
          commit('SET_CHAT_OPEN', true);
          commit('SET_CHAT_MESSAGE', data.content);
        } else if (data.component) { // Agregar esta condición
          
          commit('ESTABLECER_COMPONENTE_BUSQUEDA', {
            componente: data.component,
            parametros: data.params || {}
          });

        }

        return data;
      } catch (error) {
        console.error('Error:', error);
        throw error;
      }
    }
  }
});

export default store;